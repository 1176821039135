import PropTypes from 'prop-types'
import React from 'react'
import { FaInstagram, FaFacebook, FaYoutube } from 'react-icons/fa'
import './style.scss'

const Footer = () => (
  <footer className="siteFooter">
    <div className="footerContent">
      <div className="footerLeft">
        <div className="footerSomeIcons">
          <a
            href="https://www.instagram.com/elaketurvakeskus/"
            aria-label="Instagram"
            target="_blank"
            rel="noopener noreferrer"
          >
            <FaInstagram />
          </a>
          <a
            href="https://www.facebook.com/kysytyoelakkeesta/"
            aria-label="Facebook"
            target="_blank"
            rel="noopener noreferrer"
          >
            <FaFacebook />
          </a>
          <a
            href="https://www.youtube.com/user/Elaketurvakeskus"
            aria-label="Youtube"
            target="_blank"
            rel="noopener noreferrer"
          >
            <FaYoutube />
          </a>
        </div>
      </div>
      <div className="footerCenter">
      <div className="footerText">
          #eläkelyfe 
        </div>
      </div>
      <div className="footerRight">

      <div className="footerText">
      #eläkepätäkkä
          
        </div>
   
      </div>
     
    </div>

    <div className="footerBottom">
      <div className="footerText">
          <a
            href="https://www.tyoelake.fi/"
            target="_blank"
            rel="noopener noreferrer"
          >
            Työeläke.fi
          </a>
        </div>
        <div className="footerSlogan">Sivuston on tuottanut Eläketurvakeskus</div>
        <div className="footerSlogan">
            <a 
              href="https://www.tyoelake.fi/ohjeet/" 
              target="_blank"
              rel="noopener noreferrer"
            >
              Tietoa evästeistä
              
            </a>
          </div>
      
      </div>

    
  </footer>
)

Footer.propTypes = {
  hashtag: PropTypes.string,
}

Footer.defaultProps = {
  hashtag: '',
}

export default Footer
