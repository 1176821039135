import React from 'react'
import PropTypes from 'prop-types'
import { StaticQuery, graphql } from 'gatsby'

import './layout.scss'
import Header from './header'
import Nav from './nav'
import Hero from './hero'
import Footer from './footer'
import Next from './next'
import '../assets/css/animate.css'

const Layout = ({
  children,
  heroImg,
  current,
  bgColor,
  video,
  hero_sticker,
  videoStill
}) => (
  <StaticQuery
    query={graphql`
      query navQuery {
        posts: allWordpressWpCampaings(filter: { slug: { ne: "etusivu" } }) {
          edges {
            node {
              title
              slug
              id
              acf {
                order
              }
            }
          }
        }
      }
    `}
    render={data => (
      <div>
        <div style={{ textAlign: 'center' }}>
          <a href="#pageContent" className="sr-only">Siirry suoraan sisältöön</a>
        </div>
        <Header
          siteTitle="Eläkepätäkkä.fi"
          posts={data.posts.edges}
          current={current}
        />
        {heroImg ? (
          <Hero bgColor={bgColor} img={heroImg} sticker={hero_sticker} />
        ) : null}
        {video ? <Hero bgColor={bgColor} video={video} videoStill={videoStill} /> : null}
        <Nav posts={data.posts.edges} current={current} />
        <div
          id="pageContent"
          className="pageContent"
          style={{
            margin: `0 auto`,
            maxWidth: 800,
            padding: `0px 1.0875rem 1.45rem`,
            paddingTop: 0,
          }}
        >
          {children}
          {current !== '9' ? (
            <Next posts={data.posts.edges} current={current} />
          ) : null}
          {current === '9' ? (
            <Next frontpage={true} posts={data.posts.edges} current="1" />
          ) : null}
        </div>
        <Footer />
      </div>
    )}
  />
)

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
