import PropTypes from 'prop-types'
import React from 'react'
import Gif from '../gif'
import './style.scss'

const Hero = ({ img, video, bgColor, sticker, videoStill }) => (
  <div
    className="heroContainer"
    style={{
      backgroundColor: bgColor,
      backgroundImage: img ? `url(${img})` : null,
      backgroundRepeat: 'no-repeat',
      backgroundSize: 'cover',
      backgroundPosition: 'center',
      minHeight: img ? `50vh` : null,
    }}
  >
    <div className="heroVideoContainer">
      {!video && sticker ? (
        <div className="heroSticker">
          <img src={sticker} alt="oispa eläkkeellä" />
        </div>
      ) : null}
      {video ? <Gif gif={video} videoStill={videoStill} /> : null}
    </div>
  </div>
)

Hero.propTypes = {
  img: PropTypes.string,
}

Hero.defaultProps = {
  img: '',
}

export default Hero
