import { Link } from 'gatsby'
// import PropTypes from 'prop-types'
import React, { Component } from 'react'
import Image from '../image'
import { FaBars, FaWindowClose } from 'react-icons/fa'
import * as R from 'ramda'
import './style.scss'

class Header extends Component {
  constructor(props) {
    super(props)
    this.state = { width: 0, height: 0, open: false }
    this.updateWindowDimensions = this.updateWindowDimensions.bind(this)
  }

  componentDidMount() {
    this.updateWindowDimensions()
    window.addEventListener('resize', this.updateWindowDimensions)
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateWindowDimensions)
  }

  updateWindowDimensions() {
    if (typeof window !== 'undefined') {
      this.setState({ width: window.innerWidth, height: window.innerHeight })
    }
  }

  render() {
    const openNav = e => {
      this.setState({ open: !this.state.open })
    }
    const sorter = R.sortBy(R.path(['node', 'acf', 'order']))
    const items = sorter(this.props.posts)
    return (
      <div className="headerWrap">
        <div className="headerContainer">
          <h1 className="siteTitle" style={{ margin: 0, display: 'none' }}>
            {this.props.siteTitle}
          </h1>
          <div style={{ width: '70vw', maxWidth: `300px` }}>
            <Link to="/" aria-label="Etusivu">
              <Image filename="elakepatakka.png" alt="logo" />
            </Link>
          </div>
          {this.state.width < 800 && !this.state.open ? (
            <button
              type="button"
              aria-haspopup="true"
              aria-expanded="false"
              aria-controls="mobileNav"
              aria-label="Navigation"
              onClick={openNav}
            >
              <FaBars className="menuIcon" />
            </button>
          ) : null}
          {this.state.width < 800 && this.state.open ? (
            <button
              type="button"
              aria-haspopup="true"
              aria-expanded="true"
              aria-controls="mobileNav"
              aria-label="Navigation"
              onClick={openNav}
            >
              <FaWindowClose className="menuIcon" />
            </button>
          ) : null}
        </div>
        {this.state.width <= 800 && this.state.open ? (
          <nav
            className="navContainer mobileNavContainer"
            style={{ zIndex: 9999 }}
            tabIndex="-1"
            id="mobileNav"
          >
            <ul>
              <li>
                <Link to={`/`} aria-label="Etusivu">
                  Etusivu
                </Link>
              </li>
              {items.map(item => {
                return (
                  <li key={item.node.id}>
                    <Link
                      to={`/${item.node.slug}/`}
                      className={
                        this.props.current === item.node.acf.order
                          ? `current`
                          : null
                      }
                    >
                      {item.node.title}
                    </Link>
                  </li>
                )
              })}
            </ul>
          </nav>
        ) : null}
      </div>
    )
  }
}

export default Header
