import { Link } from 'gatsby'
import React, { Component } from 'react'
import * as R from 'ramda'
import './style.scss'

class Nav extends Component {
  constructor(props) {
    super(props)
    this.state = { width: 0, height: 0 }
    this.updateWindowDimensions = this.updateWindowDimensions.bind(this)
  }

  componentDidMount() {
    this.updateWindowDimensions()
    window.addEventListener('resize', this.updateWindowDimensions)
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateWindowDimensions)
  }

  updateWindowDimensions() {
    if (typeof window !== 'undefined') {
      this.setState({ width: window.innerWidth, height: window.innerHeight })
    }
  }

  render() {
    const sorter = R.sortBy(R.path(['node', 'acf', 'order']))
    const items = sorter(this.props.posts)
    return (
      <div>
        {this.state.width >= 800 ? (
          <nav className="navContainer">
            <ul>
              <li>
                <Link
                  to={`/`}
                  className={this.props.current === '1' ? `current` : null}
                  aria-label="Etusivu"
                >
                  Etusivu
                </Link>
              </li>
              {items.map(item => {
                return (
                  <li key={item.node.id}>
                    <Link
                      to={`/${item.node.slug}/`}
                      className={
                        this.props.current === item.node.acf.order
                          ? `current`
                          : null
                      }
                      aria-label={item.node.title}
                    >
                      {item.node.title}
                    </Link>
                  </li>
                )
              })}
            </ul>
          </nav>
        ) : null}
      </div>
    )
  }
}
export default Nav
