import PropTypes from 'prop-types'
import React, { Component } from 'react'
import './style.scss'

class Gif extends Component {
  constructor(props) {
    super(props)
    this.state = { seconds: 0, loaded: false }
    this.addSeconds = this.addSeconds.bind(this)
  }

  componentDidMount() {
    setInterval(() => {
      this.addSeconds()
    }, 1000)
  }

  addSeconds() {
    this.setState({ seconds: this.state.seconds + 1 })
  }

  render() {
    const stopVideo = () => {
      this.refs.vid.pause()
      this.setState({ seconds: 5 })
    }
    const playVideo = () => {
      this.setState({ seconds: 0 })
      this.refs.vid.play()
    }
    return (
      <div className="gifVideoContainer">
                {this.state.seconds < 4 ? (
          <video ref="vid" loop autoPlay muted playsInline onClick={stopVideo} poster={this.props.videoStill} src={this.props.gif} />):
          (
            <video ref="vid" autoPlay muted playsInline onClick={playVideo} poster={this.props.videoStill} src={this.props.gif} />
          )}
       
      </div>
    )
  }
}

Gif.propTypes = {
  gif: PropTypes.string,
  autoplay: PropTypes.bool,
  still: PropTypes.string,
}

Gif.defaultProps = {
  gif: '',
  autoplay: true,
  still: '',
}

export default Gif
